import axios from "axios";
import React, { useState } from "react";

const UploadFile: React.FC = () => {
  const [file, setFile] = useState<File | null>(null);
  const [error, setError] = useState(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFile(event.target.files[0]);
    }
  };

  console.log("file--->", file);

  const uploadFile = async () => {
    if (!file) {
      console.error("No file selected");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post(
        "https://mini-upload-api.vixayexpress.la/api/upload/upload-file",
        formData,
        {
          // headers: {
          //   'Content-Type': 'multipart/form-data',
          // },
          headers: {
            "Content-Type": " file/*; image/*",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
            "Access-Control-Allow-Headers":
              "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
          },
        }
      );

      if (response.status) {
        alert("Upload successful");
        setFile(null);
      }
      console.log(response.data);
    } catch (error: any) {
      setError(error.message);
      setFile(null);
      alert("Upload failed: " + error.message);
      console.error("Error uploading file:", error);
    }
  };

  return (
    <div>
      <h1>Upload File to DigitalOcean Spaces</h1>
      <input type="file" onChange={handleFileChange} />
      <button onClick={uploadFile}>Upload</button>

      <div>{error}</div>
    </div>
  );
};

export default UploadFile;
